<template>
  <vue-scroll>
  <v-container class="mt-4" >

    <v-text-field v-model="search" solo prepend-inner-icon="mdi-magnify" label="Rechercher"></v-text-field>

    <template v-if="$route.params.id != undefined && $route.params.id == 'welcome'">
      <welcome-news></welcome-news>
    </template>

    <template v-if="$route.params.id != undefined && $route.params.id != 'welcome'">
      <communication :news="getNews"></communication>
    </template>

    <template v-if="$route.params.id == undefined">
      <v-card v-for="(newsletter, i) in getRealNews" :key="i+'news'" link class="mb-4" @click="changeNews(newsletter._id)">
        <v-card-title>
          {{ lang == "fr" ? newsletter.label : newsletter.label_en }}
        </v-card-title>
        <v-card-subtitle>
          {{ new Date(newsletter.date).toLocaleDateString() }}
        </v-card-subtitle>
      </v-card>

      <template v-if="searchComWelcome">
        <v-card v-for="(newsletter, i) in news" :key="i+'welcome'" link class="mb-4" @click="changeNews(newsletter.id)" >
          <v-card-title>
            {{ newsletter.title }}
          </v-card-title>
          <v-card-text>
            {{ tease(newsletter.text) }}
          </v-card-text>
        </v-card>
      </template>
    </template>

  </v-container>
  </vue-scroll>
</template>

<script>
import Vuex from "vuex"
import CommunicationService from "@/Services/Communication";

export default {
  name: "PlaneteOnlineNews",
  components: {
    WelcomeNews: () => import("@/Components/Views/Home/Widgets/WidgetPlaneteOnlineNews/WelcomeNews"),
    Communication: () => import("@/Components/Views/Home/Widgets/WidgetPlaneteOnlineNews/Communication")
  },
  computed: {
    ...Vuex.mapState(["widgetBoard", "modules", "communication"]),
    getNews() {
      return this.communication.news.find(a=>a._id===this.$route.params.id)
    },
    news() {
      return [
        {
          id: "welcome",
          title: "Aujourd'hui s'ouvre une nouvelle page de notre histoire",
          text: "Chers Clients, Depuis sa création, notre logiciel PL@N.net n’a pas cessé d’évoluer et nous tenons à vous remercier pour votre participation active dans cette belle aventure."
        }
      ]
    },
    searchComWelcome() {
      let searchNeedle = this.escapeRegex(this.search)
      let matches = this.news[0].title.match(this.searchNoAccents(searchNeedle))
      return this.search.length == 0 || (matches||[]).length > 0
    },
    getRealNews() {
      return this.communication.news.slice().filter(com => {
        let date = com.date
        if (date && !(date instanceof Date)) {
          date = new Date(date)
        }
        let expiration = null
        if (com.expiration) {
          expiration = new Date(com.expiration)
        }

        let rights
        if (com.module.length) {
          let allSubModules = []
          this.modules.forEach(mod => {
            mod.subModules.map(a => a.path).forEach(submod => allSubModules.push(submod))
          })
          rights = com.module.some(comMod => // Hé attention, je suis pas comMod
              allSubModules.indexOf(comMod.path) !== -1
          )
        } else {
          rights = true
        }

        let searchNeedle = this.escapeRegex(this.search).toLowerCase()
        let matches = []
        if (this.lang == "fr") {
            matches = com.label.match(this.searchNoAccents(searchNeedle))
        } else {
            matches = com.label_en.match(this.searchNoAccents(searchNeedle))
        }
        let search = searchNeedle == "" || (matches||[]).length > 0

        return date && new Date() >= date && (!com.module || rights) && search && (!expiration || expiration > new Date())
        // Retourne true si :
        // Il y a une date et qu'elle est dans le passé
        // &&
        // Qu'il n'y a pas d'expiration ou que celle-ci est dans le futur
        // &&
        // Qu'il n'y a aucun module de lié (donc com globale) ou qu'il y a un/plusieurs modules dont il a les droits
      })
    },
    lang() {
      return this.$i18n.locale == "en" ? "en" : "fr"
    }
  },
  data() {
    return {
      search: ""
    }
  },
  methods: {
    tease: function (str) {
      return str.substr(0,160) + "..."
    },
    changeNews: function (id) {
      this.$router.push({name: 'PlaneteOnlineNews', params: { id: id }})
    },
  }
}
</script>

<style scoped>

</style>